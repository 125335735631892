import React from 'react'

function Upload() {
  return (
    <div className="container positioning">
        <h1>Upload Folder</h1>
        <h1>Heading</h1>
        <input type="email" placeholder="Email to" />
        <input type="email" placeholder="Your email" />
        <input type="text" placeholder="Title" />
        <input type="text" placeholder="Message" />
    </div>
  )
}

export default Upload