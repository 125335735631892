
import React from 'react'

function Header() {
  return (
    <header>
        <div className="container tips positioning">
          <span>Dont close the window while uploading the folder</span>
          <span>Please support:</span>
          <span>Payment method: Esewa (9849949984)</span></div>
    </header>
  )
}

export default Header